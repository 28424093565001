export const ThawData = {
  summary: {
    name: "Bennyboer/Thaw",
    description:
      "Thaw is a tool to create documents with export to PDF in a text-concentrated working style.",
    tools: ["Java", "Gradle"],
    contribution: 1,
    contributionLink:
      "https://github.com/pulls?q=is%3Apr+author%3Abadalsarkar+archived%3Afalse+is%3Aclosed+repo%3Abennyboer%2Fthaw",
    detailsLink: "/work-details/opensource/thaw",
  },
  details: {
    about:
      "Thaw is a tool to create documents with export to PDF in a \
      text-concentrated working style. It offers a feature-rich and \
      easy to learn markup language that you can write your documents in a human-readable way.",
    tools: ["Java", "Gradle"],
    currentlyWorking: [],
    completedWork: [
      {
        title: "Implement new CLI argument to clear cache",
        link: "https://github.com/bennyboer/thaw/pull/64",
        type: "New Feature",
      },
    ],
    blogs: [],
  },
}
